import "@fontsource/inter"
import "@fontsource/inter/500.css"
import "@fontsource/inter/700.css"
import "modern-normalize"
import React from "react"
import {
  Cta,
  Feature,
  Gallery,
  Introduction,
  Layout,
  OtherPartner,
  Partner,
  Seo,
  Why
} from "../components"
import "../styles/global.css"
import * as styles from "./index.module.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Giải Pháp Vận Hành Phòng Net Toàn Diện" />
    <div className={styles.content}>
      <Introduction />
      <Partner />
      <Why />
      <Feature />
      <OtherPartner />
      <Gallery />
      <Cta />
    </div>
  </Layout>
)

export default IndexPage
